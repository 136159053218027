import React from 'react'
import { AnnouncementMarquee, AppTable, BannerGallery, CardComponent, GameCards } from '../../components'
import { Flex } from 'antd'

const Dashboard = () => {
  return (
    <Flex vertical gap={20}>
        <AnnouncementMarquee />
        <CardComponent />
        <BannerGallery />
        <AppTable />
        <GameCards />
    </Flex>
  )
}

export {Dashboard}