import React, { useState } from 'react'
import { Card, Col, Flex, Row, Space, Typography } from 'antd'
import './index.css'
import { ModuleTopHeading } from '../PageComponents/ModuleTopHeading'
import { ActionButton } from '../PageComponents/ActionButton'
import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { AlertModal } from '../AlertModal'

const { Text } = Typography

const GameCards = () => {

    const [ visiblemodal, setVisibleModal ] = useState(false)

    const  innerdata=[
            {
                img:'im-7.jpg',
                title:'Number Slot',
                path:'/',
            },
            {
                img:'im-8.jpg',
                title:'Pool Number',
                path:'/',
            },
            {
                img:'im-9.jpg',
                title:'Roulette',
                path:'/',
            },
            {
                img:'im-10.jpg',
                title:'Casino Dice',
                path:'/',
            },
            {
                img:'im-11.png',
                title:'Keno',
                path:'/',
            },
            {
                img:'im-12.jpg',
                title:'Black Jack',
                path:'/',
            },
            {
                img:'im-13.png',
                title:'Mines',
                path:'/',
            },
        ]
  return (
    <div>
        <Card className='card-bg border0 rounded py-3'>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <ModuleTopHeading
                        name='Recent Games'
                    />
                </Col>
                {
                    innerdata?.map((condata,index)=>
                        <Col xs={24} sm={12} md={8} lg={4} key={index}>
                            <Card className='bg-transparent border0 card-game'>
                                <div className='img'>
                                    <img src={'/assets/games/'+condata?.img} />
                                </div>
                                <div className='btnplay'>
                                    <Space align='center'>
                                        <ActionButton
                                            style={{height:'auto'}}
                                            title='Play game'
                                            shape="circle"
                                            icon={<Flex ><PlayCircleOutlined className='brand-color' style={{fontSize:'22px',padding:'5px'}}/></Flex>}
                                            // onClick={() => { setVisible(true)}}
                                            className='borderwhite'
                                        />
                                        <ActionButton
                                            style={{height:'auto'}}
                                            title='Delete item'
                                            icon={
                                                <Flex align='center' justify='center'>
                                                    <DeleteOutlined className='brand-color' style={{fontSize:'22px',padding:'5px'}} />
                                                </Flex>
                                            }
                                            onClick={() => { setVisibleModal(true) }}
                                            shape="circle"
                                            className='borderwhite'
                                        />
                                    </Space>
                                </div>
                                <Flex justify='center'>
                                    <Text className='text-white py-2'>
                                        {
                                            condata?.title
                                        }
                                    </Text>
                                </Flex>
                            </Card>
                        </Col>
                    )
                }
            </Row>
        </Card>
        <AlertModal
            visiblemodal={visiblemodal}
            onClose={()=>setVisibleModal(false)}
        />
    </div>
  )
}

export {GameCards}