import React from 'react'
import { Card, Col, Image, Row, Space, Typography } from 'antd'
import { ModuleTopHeading } from '../../PageComponents'

const { Title, Text } = Typography

const TotalSuccessfulReferralCard = () => {

    const data = [
        {
            title:'0.00',
            subtitle:'Total successful referral',
            img:'ref.png',
        },
    ]

  return (
    <div>
        <Card className='card-bg rounded border0'>
            <Row gutter={[24,24]}>
                <Col xs={24} sm={24} md={12} lg={18}>
                <ModuleTopHeading
                    name='Successful referral'
                />
                </Col>
                {
                    data?.map((data,index)=>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} key={index}>
                            <Card className='bg-main border0 rounded'>
                                <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                                    <div className='my-2'>
                                        <Text className='text-white' strong>{data?.subtitle}</Text>
                                        <Title level={3} className='text-white my-0'>{data?.title}</Title>
                                    </div>
                                    <Image src={'/assets/icons/'+data?.img} width={'40px'} />
                                </Space>
                            </Card>  
                        </Col>
                    )
                }
            </Row>
        </Card>
    </div>
  )
}

export {TotalSuccessfulReferralCard}