import React from 'react'
import { Card, Col, Flex, Row } from 'antd'
import { TotalSuccessfulReferralCard } from './TotalSuccessfulReferralCard'
import { ListofSuccessfulReferral } from './ListofSuccessfulReferral'
import { ModuleTopHeading } from '../PageComponents'

const ReferralCards = () => {



  return (
    <div>
        <Flex vertical gap={20}>
          <TotalSuccessfulReferralCard />
          <ListofSuccessfulReferral />
        </Flex>
    </div>
  )
}

export {ReferralCards}