import React, { useState } from "react"
import { Drawer, Form, Row, Col, Button, Space, Typography, Image, Upload } from "antd"
import { MyInput } from '../../components';
import { Casselect } from "../Casselect"
import { PlusOutlined } from '@ant-design/icons';

const { Text } = Typography

const AddTicketDrawer = ({ visible, onClose, visibleonly }) => {

    const [form] = Form.useForm()
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');


    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const [fileList, setFileList] = useState([]);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <button
        style={{
            border: 0,
            background: 'none',
        }}
        type="button"
        >
        <PlusOutlined />
        <div
            style={{
            marginTop: 8,
            }}
        >
            Upload
        </div>
        </button>
    );

  return (
    <div>
        <Drawer
            title={<Text className="text-white">Add Ticket</Text>}
            onClose={onClose}
            className="card-bg"
            open={visible}
            width={700}
            footer={
                <div className="w-100">
                    <Space className="w-100">
                        <Button
                            onClick={onClose}
                            className="btn-cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            className="bg-gradient border0"
                            onClick={()=>form.submit()}
                        >
                            Save
                        </Button>
                    </Space>
                </div>
            }
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    remember: true,
                }}
                // onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MyInput
                            name='subject'
                            label='Subject'
                            required
                            message='Please enter your subject'
                            placeholder='123,000'
                            value={form.getFieldValue("subject") || ''} 
                            disabled={visibleonly}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Casselect
                            label= 'Priority'
                            name="priority" 
                            required
                            message='Please choose priority'
                            placeholder='High'
                            value={form.getFieldValue("priority")}
                            className='text-white'
                            options={[
                                {name:"High", id:'high'},
                                {name:"Medium", id:'medium'},
                                {name:"Low", id:'low'},
                            ]}
                            disabled={visibleonly}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            textArea
                            name='message'
                            label='Message'
                            required
                            message='Please enter your message'
                            placeholder='Enter your message here'
                            value={form.getFieldValue("message") || ''} 
                            className='bg-secondary'
                            disabled={visibleonly}
                        />
                    </Col>
                    {
                        !visibleonly &&
                        <Col span={24}>
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 8 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                wrapperStyle={{
                                    display: 'none',
                                }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                                />
                            )}
                        </Col>
                    }
                    
                </Row>
            </Form>
        </Drawer>
    </div>
  )
}

export {AddTicketDrawer}