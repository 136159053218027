import React, { useState } from 'react'
import { Modal, Space, Typography, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
import './index.css'

const { Title, Text } = Typography

const AlertModal = ({visiblemodal,onClose,}) => {
    
    const [ loading, setLoading ] = useState(false)

    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }
    
  return (
    <div>
        <Modal width={500} className='modal'  open={visiblemodal} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <DeleteOutlined 
                    style={{fontSize: '40px'}} 
                    className='text-white'
                />
                <Title level={4} className='my-0 text-white'>Alert</Title>
                <Text className='text-white text-center'>Are you sure you want to permanently delete <strong>{visiblemodal? visiblemodal?.name: '' }</strong></Text>
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btn-cancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        type="primary"
                        danger
                        loading={loading}
                        className='bg-gradient border0'
                        // onClick={()=>{
                        //     DeleteProductApi();
                        // }}
                    >
                        Confirm
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertModal}