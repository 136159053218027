import React, { useState } from "react"
import { Drawer, Form, Row, Col, Button, Space, Typography } from "antd"
import { MyInput } from '../../components';
import { Casselect } from "../Casselect"

const { Text } = Typography

const BankDrawer = ({ visible, onClose }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    
    return (
        <div>
            <Drawer
                title={<Text className="text-white">Add Bank</Text>}
                onClose={onClose}
                className="card-bg"
                open={visible}
                width={700}
                footer={
                    <div className="w-100">
                        <Space className="w-100">
                            <Button
                                onClick={onClose}
                                className="btn-cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                block
                                type="primary"
                                loading={loading}
                                htmlType="submit"
                                className="bg-gradient border0"
                                onClick={()=>form.submit()}
                            >
                                Save
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='bankName'
                                label='Bank name'
                                required
                                message='Please enter your bank name'
                                value={form.getFieldValue("bankName") || ''} 
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='accountNumber'
                                label='Bank account number'
                                required
                                message='Please enter your bank account number'
                                value={form.getFieldValue("accountNumber") || ''} 
                            />
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export {BankDrawer}