import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Flex, Dropdown, Card } from 'antd'
import { FilterOutlined } from '@ant-design/icons';
import { MyInput, MyInputnoLabel } from '../../Forms';
import { AlertModal } from '../../AlertModal';
import { ActionButton, ModuleTopHeading} from '../../PageComponents';
const { Text } = Typography

const ListofSuccessfulReferral = () => {

    const [form] = Form.useForm();
    const [ visiblemodal, setVisibleModal ] = useState(false)
    
    const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];
  
      const columns = [
          {
              title: <Text className='text-white'>Name</Text>,
              dataIndex: 'name',
          },
          {
              title: <Text className='text-white'>Email</Text>,
              dataIndex: 'email',
          }
      ];
  
      const data = [
          {
              key: '1',
              name: <Text className='text-white'>Ahmed</Text>,
              email: <Text className='text-white'>ahmed@gmail.com</Text>,
          },
          {
              key: '2',
              name: <Text className='text-white'>Ali</Text>,
              email: <Text className='text-white'>ali@gmail.com</Text>,
          },
          {
              key: '3',
              name: <Text className='text-white'>Sheraz</Text>,
              email: <Text className='text-white'>sheraz@gmail.com</Text>,
          },
          {
              key: '4',
              name: <Text className='text-white'>Awais</Text>,
              email: <Text className='text-white'>awais@gmail.com</Text>,
          },
          {
              key: '5',
              name: <Text className='text-white'>Zubair</Text>,
              email: <Text className='text-white'>zubair@gmail.com</Text>,
          },
        ];
  
    return (
        <div>
            <Card className='card-bg rounded border0'>
                <Row gutter={[24,24]}>
                    <Col xs={24} sm={24} md={12} lg={18}>
                        <ModuleTopHeading
                            name='List of successful referral'
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='end' gap={10}>
                                <MyInputnoLabel
                                    name='name'
                                    label='Search'
                                    placeholder='Search here ...'
                                    value={form.getFieldValue("name") || ''} 
                                    className='w-100'
                                    onChange={(e)=>e.target.value}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    mode
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                        className='bg-main border0 text-white btn-filter'
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
                <AlertModal
                    visiblemodal={visiblemodal}
                    onClose={()=>setVisibleModal(false)}
                />
            </Card>
        </div>
    )
}

export {ListofSuccessfulReferral}