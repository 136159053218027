import React from 'react'
import { Card, Image } from 'antd'

const GameBanner = () => {
  return (
    <div>
        <Card className='card-bg border0'>
            <Image src='/assets/banner-3.png' preview={false} width={'100%'} className='rounded' />
        </Card>
    </div>
  )
}

export {GameBanner}