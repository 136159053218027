import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row, Space, Typography } from 'antd'
import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { MyInput } from '../Forms/MyInput'
import { ActionButton } from '../PageComponents'
import { MyInputnoLabel } from '../Forms/MyInputnoLabel'
import { AlertModal } from '../AlertModal'

const { Text } = Typography

const GameComponent = () => {

    const [ current, setCurrent ] = useState(0)
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [ visiblemodal, setVisibleModal ] = useState(false)

    const data = {
        head:[
            {
                item:'All'
            },
            {
                item:'Trending Games'
            },
            {
                item:'Adventure Games'
            },
            {
                item:'Sport Games'
            },
        ],
        content:[
            {
                innerdata:[
                    {
                        img:'im-1.png',
                        title:'Head & Tail',
                        path:'/',
                    },
                    {
                        img:'im-2.jpg',
                        title:'Rock Paper scissors',
                        path:'/',
                    },
                    {
                        img:'im-3.jpg',
                        title:'Spin Wheel',
                        path:'/',
                    },
                    {
                        img:'im-4.jpg',
                        title:'Number Guessing',
                        path:'/',
                    },
                    {
                        img:'im-5.jpg',
                        title:'Dice Rolling',
                        path:'/',
                    },
                    {
                        img:'im-6.jpg',
                        title:'Card Finding',
                        path:'/',
                    },
                    {
                        img:'im-7.jpg',
                        title:'Number Slot',
                        path:'/',
                    },
                    {
                        img:'im-8.jpg',
                        title:'Pool Number',
                        path:'/',
                    },
                    {
                        img:'im-9.jpg',
                        title:'Roulette',
                        path:'/',
                    },
                    {
                        img:'im-10.jpg',
                        title:'Casino Dice',
                        path:'/',
                    },
                    {
                        img:'im-11.png',
                        title:'Keno',
                        path:'/',
                    },
                    {
                        img:'im-12.jpg',
                        title:'Black Jack',
                        path:'/',
                    },
                    {
                        img:'im-13.png',
                        title:'Mines',
                        path:'/',
                    },
                ],
            },
            {
                innerdata:[
                    {
                        img:'im-6.jpg',
                        title:'Card Finding',
                        path:'/',
                    },
                    {
                        img:'im-7.jpg',
                        title:'Number Slot',
                        path:'/',
                    },
                    {
                        img:'im-8.jpg',
                        title:'Pool Number',
                        path:'/',
                    },
                    {
                        img:'im-9.jpg',
                        title:'Roulette',
                        path:'/',
                    },
                    {
                        img:'im-10.jpg',
                        title:'Casino Dice',
                        path:'/',
                    },
                    {
                        img:'im-11.png',
                        title:'Keno',
                        path:'/',
                    },
                ],
            }, 
            
            {
                innerdata:[
                    {
                        img:'im-11.png',
                        title:'Keno',
                        path:'/',
                    },
                    {
                        img:'im-12.jpg',
                        title:'Black Jack',
                        path:'/',
                    },
                    {
                        img:'im-13.png',
                        title:'Mines',
                        path:'/',
                    },
                    {
                        img:'im-9.jpg',
                        title:'Roulette',
                        path:'/',
                    },
                    {
                        img:'im-10.jpg',
                        title:'Casino Dice',
                        path:'/',
                    },
                    {
                        img:'im-8.jpg',
                        title:'Pool Number',
                        path:'/',
                    },
                ],
            }, 

            {
                innerdata:[
                    {
                        img:'im-4.jpg',
                        title:'Number Guessing',
                        path:'/',
                    },
                    {
                        img:'im-5.jpg',
                        title:'Dice Rolling',
                        path:'/',
                    },
                    {
                        img:'im-6.jpg',
                        title:'Card Finding',
                        path:'/',
                    },
                    {
                        img:'im-7.jpg',
                        title:'Number Slot',
                        path:'/',
                    },
                    {
                        img:'im-8.jpg',
                        title:'Pool Number',
                        path:'/',
                    },
                    {
                        img:'im-9.jpg',
                        title:'Roulette',
                        path:'/',
                    },
                    {
                        img:'im-10.jpg',
                        title:'Casino Dice',
                        path:'/',
                    },
                ],
            }, 
        ]
        
    }

  return (
    <div>
        <Card className='card-bg border0 mb-3'>
            <Row gutter={[12,12]}>
                <Col xs={24} sm={24} md={16}>
                    <Flex gap={'small'} className='scroll-mbl'>
                    {
                        data?.head?.map((headtext,index)=>
                            <Button key={index} onClick={()=>setCurrent(index)} className={current===index?'btnactive btnpill':'btnpill'}>
                                {
                                    headtext?.item
                                }
                            </Button>
                        )
                    }
                        </Flex>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form 
                        layout='vertical'
                    >
                        <MyInputnoLabel
                            name='name'
                            label='Search'
                            placeholder='Search here ...'
                            value={form.getFieldValue("name") || ''} 
                            className='w-100'
                            onChange={(e)=>e.target.value}
                        />
                    </Form>
                </Col>
            </Row>
        </Card>
        <Row gutter={[24,24]}>
            {
                data?.content[current]?.innerdata?.map((condata,index)=>
                    <Col xs={24} sm={12} md={8} lg={4} key={index}>
                        <Card className='bg-transparent border0 card-game'>
                            <div className='img'>
                                <img src={'/assets/games/'+condata?.img} />
                            </div>
                            <div className='btnplay'>
                                <Space align='center'>
                                    <ActionButton
                                        style={{height:'auto'}}
                                        title='Play game'
                                        shape="circle"
                                        icon={<Flex ><PlayCircleOutlined className='brand-color' style={{fontSize:'22px',padding:'5px'}}/></Flex>}
                                        // onClick={() => { setVisible(true)}}
                                        className='borderwhite'
                                    />
                                    <ActionButton
                                        style={{height:'auto'}}
                                        title='Delete item'
                                        icon={
                                            <Flex align='center' justify='center'>
                                                <DeleteOutlined className='brand-color' style={{fontSize:'22px',padding:'5px'}} />
                                            </Flex>
                                        }
                                        onClick={() => { setVisibleModal(true) }}
                                        shape="circle"
                                        className='borderwhite'
                                    />
                                </Space>
                            </div>
                            <Flex justify='center' className='py-2'>
                                <Text className='text-white text-center'>
                                    {
                                        condata?.title
                                    }
                                </Text>
                            </Flex>
                        </Card>
                    </Col>
                )
            }
        </Row>

        <AlertModal 
            visiblemodal={visiblemodal}
            onClose={()=>setVisibleModal(false)}
        />
    </div>
  )
}

export {GameComponent}