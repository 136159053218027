import React, { useState } from "react"
import { Drawer, Form, Row, Col, Button, Space, Typography } from "antd"
import { MyInput } from '../../components';
import { Casselect } from "../Casselect"

const { Text } = Typography

const WithdrawDrawer = ({ visible, onClose }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    
    return (
        <div>
            <Drawer
                title={<Text className="text-white">Withdraw Money</Text>}
                onClose={onClose}
                className="card-bg"
                open={visible}
                width={700}
                footer={
                    <div className="w-100">
                        <Space className="w-100">
                            <Button
                                onClick={onClose}
                                className="btn-cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                block
                                type="primary"
                                loading={loading}
                                htmlType="submit"
                                className="bg-gradient border0"
                                onClick={()=>form.submit()}
                            >
                                Save
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='amount'
                                label='Amount'
                                required
                                message='Please enter your amount'
                                value={form.getFieldValue("amount") || ''} 
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <Casselect
                                label= 'Select bank'
                                name="bank" 
                                showSearch
                                required
                                message='Please choose bank'
                                value={form.getFieldValue("bank")}
                                className='text-white'
                                options={[
                                    {name:"UBL", id:'ubl'},
                                    {name:"Habib Bank", id:'hb'},
                                    {name:"Allied Bank", id:'alliedbank'},
                                    {name:"MCB Bank", id:'mcb'},
                                    {name:"Askare Bank", id:'askare'},
                                ]}
                            />
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export {WithdrawDrawer}