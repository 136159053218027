import React from 'react'
import { Card, Image } from 'antd';
import Slider from "react-slick";
import './index.css'
import { ModuleTopHeading } from '../PageComponents';

const BannerGallery = () => {

    const data = [
        {
            img:'im-1.png'
        },
        {
            img:'im-2.jpg'
        },
        {
            img:'im-3.jpg'
        },
        {
            img:'im-4.jpg'
        },
        {
            img:'im-5.jpg'
        },
        {
            img:'im-6.jpg'
        },
        {
            img:'im-7.jpg'
        },
    ]

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };

  return (
    <div>
        <Card className='card-bg border0 py-3 px-3'>
            <ModuleTopHeading 
              name='Banner Gallery'
            />
            <Slider {...settings}>
                {
                    data?.map((data,index)=>
                        <div key={index} className='img-gallery'>
                            <Image src={'/assets/games/'+data?.img} preview={false} />
                        </div>
                    )
                }
            </Slider>
        </Card>
    </div>
  )
}

export {BannerGallery}