import React from 'react'
import { Card, Col, Flex, Form, Row, Typography } from 'antd'
import { MyInput, ReferralCards } from '../../components'
import { PaperClipOutlined } from '@ant-design/icons'

const { Text } = Typography

const Referral = () => {
    
    const [form] = Form.useForm()

  return (
    <div>
        <Flex vertical gap={20}>
            <Card className='card-bg rounded border0'>
                <Row gutter={[12,12]}>
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={{
                                remember: true,
                            }}
                        >
                            <MyInput
                                name='referral'
                                label='Referral Link'
                                suffix={<Text className='text-white'>Copy</Text>}
                                value={form.getFieldValue("referral") || ''} 
                                className='bg-main border0'
                            />
                        </Form>
                    </Col>
                </Row>
            </Card>
            <ReferralCards />
        </Flex>
    </div>
  )
}

export {Referral}