import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, Button, Flex } from 'antd'
import './index.css'
import { ModuleTopHeading } from '../PageComponents/ModuleTopHeading'

const { Title, Text } = Typography
const CardComponent = () => {

    const [ current, setCurrent ] = useState(0)


    const data = [
        {
            title:'0.00 Peso',
            subtitle:'Total Win',
            img:'win.png',
        },
        {
            title:'0.00 Peso',
            subtitle:'Total Loss',
            img:'loss.png',
        },
        {
            title:'0.00 Peso',
            subtitle:'Balance',
            img:'balance.png',
        },
    ]

  return (
    <div>
        <Card className='card-bg border0 py-3'>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <ModuleTopHeading 
                        name='Overview of player activities'
                    />
                </Col>
                {/* <Col span={24}>
                    <Flex gap={'small'} className='scroll-mbl'>
                        {
                            data?.head?.map((headtext,index)=>
                                <Button type='primary' key={index} onClick={()=>setCurrent(index)} className={current===index?'btnactive btnpill':'btnpill'}>
                                    {
                                        headtext?.item
                                    }
                                </Button>
                            )
                        }
                    </Flex>
                </Col> */}
                {/* {
                    data?.content[current]?.innerdata?.map((condata,c)=>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} key={c}>
                            <Card className='bg-main border0 rounded'>
                                <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                                    <div className='my-2'>
                                        <Text className='text-white' strong>{condata?.subtitle}</Text>
                                        <Title level={3} className='text-white my-0'>{condata?.title}</Title>
                                    </div>
                                    <Image src={'/assets/icons/'+condata?.img} width={'40px'} />
                                </Space>
                            </Card>  
                        </Col>
                    )
                } */}

                {
                    data?.map((condata,index)=>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} key={index}>
                            <Card className='bg-main border0 rounded'>
                                <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                                    <div className='my-2'>
                                        <Text className='text-white' strong>{condata?.subtitle}</Text>
                                        <Title level={3} className='text-white my-0'>{condata?.title}</Title>
                                    </div>
                                    <Image src={'/assets/icons/'+condata?.img} width={'40px'} />
                                </Space>
                            </Card>  
                        </Col>
                    )
                }
            </Row>
        </Card>
    </div>
  )
}

export {CardComponent}