import React from 'react'
import { Button, Card, Col, Flex, Row, Typography } from 'antd'
import './index.css'

const { Title, Text } = Typography

const AnnouncementCard = () => {

    const data =[
        {
            userimg:'user.png',
            username: '****ibo',
            award:'trophy-gray.png',
            title:'Lightning Roulette',
            earn:'Earn',
            amount:'125,000',
            key:'tropy'
        },
        {
            userimg:'user.png',
            username: '****ibo',
            title:'Lightning Roulette',
            earn:'Earn',
            amount:'125,000',
            
        },
        {
            userimg:'user.png',
            username: '****ibo',
            title:'Lightning Roulette',
            earn:'Earn',
            amount:'125,000',
        },
        {
            userimg:'user.png',
            username: '****ibo',
            award:'trophy-gold.png',
            title:'Lightning Roulette',
            earn:'Earn',
            amount:'125,000',
            key:'tropy'
        },
        {
            userimg:'user.png',
            username: '****ibo',
            title:'Lightning Roulette',
            earn:'Earn',
            amount:'125,000',
        },
        {
            userimg:'user.png',
            username: '****ibo',
            title:'Lightning Roulette',
            earn:'Earn',
            amount:'125,000',
        },
        
    ]

  return (
    <div>
        <Row gutter={[12,12]}>
            <Col xs={24} sm={24} md={10} lg={8}>
                <Card className='bg-main border0 rounded card-p-sm'>
                    <Flex gap={10} vertical>
                        <Flex justify='space-between' align='center'>
                            <Flex gap={10} align='center'>
                                <img src='/assets/icons/user.png' width={50} />
                                <Title level={5} className='text-white m-0'>
                                    ****ibo
                                </Title>
                            </Flex>
                            <img src='/assets/icons/trophy-gold.png' width={35} />
                        </Flex>
                        <Flex justify='space-between' align='center'>
                            <Text className='text-white'>
                                Lightning Roulette
                            </Text>
                            <Button className='btn rounded-50'>
                                Play
                            </Button>
                        </Flex>
                        <Flex justify='space-between' align='center'>
                            <Text italic strong className='text-gold m-0'>
                                Earn
                            </Text>
                            <Title italic level={2} className='text-gold m-0'>
                                125,000
                            </Title>
                        </Flex>
                    </Flex>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={14} lg={16}>
                <Row gutter={[12,12]}>
                    {
                        data?.map((data,index)=>
                            <Col xs={24} sm={24} md={12} lg={8} key={index}>
                                <Card className='bg-main border0 rounded card-p-sm'>
                                    <Flex gap={10} vertical>
                                        <Flex justify='space-between' align='center'>
                                            <Flex gap={10} align='center'>
                                                <img src={'/assets/icons/'+data?.userimg} width={30} />
                                                <Text strong className='text-white m-0 fs-12'>
                                                    {
                                                        data?.username
                                                    }
                                                </Text>
                                            </Flex>
                                            {
                                                data?.key === 'tropy' ?
                                                <img src={'/assets/icons/'+data?.award} width={30} alt='' />
                                                :
                                                <></>   
                                            }
                                        </Flex>
                                        <Flex justify='space-between' align='center'>
                                            <Text className='text-white fs-12'>
                                                {
                                                    data?.title
                                                }
                                            </Text>
                                            <Button className='btn-gray-sm  bg-gradient border0 rounded-50'>
                                                Play
                                            </Button>
                                        </Flex>
                                        <Flex justify='space-between' align='center'>
                                            <Text italic strong className='text-gold m-0 fs-12'>
                                                {
                                                    data?.earn
                                                }
                                            </Text>
                                            <Title italic level={3} className='text-gold m-0'>
                                                {
                                                    data?.amount
                                                }
                                            </Title>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </Col>
        </Row>
    </div>
  )
}

export {AnnouncementCard}