import React from 'react'
import { Card, Col, Row, Typography } from 'antd'
import Marquee from 'react-fast-marquee'
import { ModuleTopHeading } from '../PageComponents/ModuleTopHeading'
import { AnnouncementCard } from './AnnouncementCard'

const AnnouncementMarquee = () => {
  return (
    <div>
        <Card className='card-bg border0'>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <ModuleTopHeading
                        name='Annoucement'
                    />
                </Col>
                <Col span={24}>
                    {/* <Marquee 
                        gradientWidth={200} 
                        gradient={true} 
                        gradientColor='rgba(24, 35, 47, 1), rgba(24, 35, 47, 0)'
                    >
                        <Title level={4} className='text-white m-0'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum laborum fugiat sint quisquam ad eligendi
                        </Title>
                    </Marquee> */}
                    <AnnouncementCard />
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {AnnouncementMarquee}