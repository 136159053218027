import { Card, Col, Image, Row, Space, Typography } from 'antd'
import React from 'react'

const { Title, Text } = Typography

const TransactionCardComponent = () => {

    const data = [
        {
            title:'0.00 Peso',
            subtitle:'Win',
            img:'win.png',
        },
        {
            title:'0.00 Peso',
            subtitle:'Loss',
            img:'loss.png',
        },
        {
            title:'0.00 Peso',
            subtitle:'Rebate',
            img:'balance.png',
        },
        {
            title:'0.00 Peso',
            subtitle:'Withdraw',
            img:'withdrawal.png',
        },
        {
            title:'0.00 Peso',
            subtitle:'Deposit',
            img:'deposit.png',
        },
    ]

  return (
    <div>
        <Row gutter={[24,24]}>
            {
                data?.map((condata,index)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={index}>
                        <Card className='bg-main border0 rounded'>
                            <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                                <div className='my-2'>
                                    <Text className='text-white' strong>{condata?.subtitle}</Text>
                                    <Title level={3} className='text-white my-0'>{condata?.title}</Title>
                                </div>
                                <Image src={'/assets/icons/'+condata?.img} width={'40px'} />
                            </Space>
                        </Card>  
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {TransactionCardComponent}