import React from 'react'
import { GameBanner, GameComponent } from '../../components'
import { Flex } from 'antd'

const Games = () => {
  return (
    <div>
        <Flex vertical gap={20}>
          <GameBanner />
          <GameComponent />
        </Flex>
    </div>
  )
}

export {Games}